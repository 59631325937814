<template>
  <div id="components-layout-demo-top-side-2">
    <div class="header">
      <div class="content-end header-box">
        <div class="logo herader-align-center" >
          <img
            src="../assets/img/logo1.png"
            style="margin-left: 40px; width: 80%"
            alt=""
          />
        </div>

          <a-menu
              class="herader-menu herader-align-center content-end"
              mode="horizontal"
              v-model="$store.state.homeData.homeMenuDefaultSelectedKeys"
              @click="menuItem"
              :select="menuItem"
          >
            <a-menu-item
                class="title"
                :key="index"
                v-for="(item, index) in pathArray"
                v-if="item.meta.showInNav == true"
            >
              <router-link

                  :to="item.path"
                  class="tiltle-item"
              >{{ item.name }}
              </router-link>
            </a-menu-item>
          </a-menu>

        <div class="header-right">
          <div v-if="$store.state.user.userInfo" style="color: #1da57a">
            {{ JSON.parse($store.state.user.userInfo).username }}
          </div>
          <div v-else class="login-button" @click="handleLogin">登录</div>
          <a
            v-if="$store.state.user.userInfo"
            href="javascript:;"
            @click="loginOut"
            class="signOut"
          >
            <a-icon type="poweroff" />
            <span class="signOut">退出</span>
          </a>
        </div>
        <!-- <a-badge :count="datahistory.length" dot @click="showDrawer">
            <a-icon type="user" :class="{ 'icon-white': messageIsActive }" />
          </a-badge> -->
      </div>
    </div>
    <a-drawer
      width="35%"
      title="消息通知"
      :placement="placement"
      :closable="false"
      :visible="visible"
      @close="onClose()"
    >
      <ul class="message-content">
        <li ref="li" v-for="(item, index) in datahistory" :key="index">
          <div class="message-item">{{ item.content }}</div>
          <a-button class="button-off" @click="showMessageModal(index, item)"
            >查看
            <a-modal
              :visible="messageVisible"
              :footer="null"
              @cancel="handleCancel"
              :mask="false"
              class="message-read"
            >
              <p>{{ datahistory[messagesItemIndex].content }}</p>
              <!--            <template slot="footer">-->
              <a-button class="btn-read" key="back" @click="handleRead()">
                已读
              </a-button>
              <!--            </template>-->
            </a-modal>
          </a-button>
        </li>
      </ul>
    </a-drawer>

    <a-layout class="content-height">
      <a-layout-content class="content">
        <keep-alive v-if="shouldKeepAlive">
          <router-view
            style="padding: 85px 24px 24px 24px; min-height: 800px"
          ></router-view>
        </keep-alive>
        <router-view
          v-else
          style="padding: 85px 24px 24px 24px; min-height: 800px"
        ></router-view>
        <BottomPageVue />
        <!-- <div class="box-footer">
          Copyright
          <a-icon type="copyright" />
          {{ company }}
        </div> -->
      </a-layout-content>
      <a-layout-footer> </a-layout-footer>
    </a-layout>
  </div>
</template>
<script>
import router from "@/router";

import BottomPageVue from "./BottomPage.vue";
export default {
  name: "OverallSituationLayout",
  components: { BottomPageVue },
  data() {
    return {
      pathArray: [],
      messagesItemIndex: 0,
      messageItemId: 0,
      messageVisible: false,
      confirmLoading: false,
      datahistory: [],
      company: "包头市资产交易平台",
      visible: false,
      placement: "right",
      offset: [8, 0],
      messageIsActive: true,
      message: 0,
      collapsed: false,
    };
  },
  created() {
    // console.log(router);
    // console.log(window.location.hash.substring(1));
    this.pathArray = router.options.routes[0].children;
    // console.log(this.pathArray);
    let hash = window.location.hash;
    for (let i = 0; i < this.pathArray.length; i++) {
      this.$store.state.homeData.routesChildrens.push(this.pathArray[i].path);
      if (hash.substring(1) == this.pathArray[i].path) {
        this.$store.commit("homeData/setmenuKey", i);
      }
    }
  },
  computed: {
    // 定义一个计算属性来决定是否应该使用 keep-alive
    shouldKeepAlive() {
      // 这里你可以根据 $route 的不同属性来返回 true 或 false
      console.log(this.$route);
      // 例如，检查路由的 meta 字段中是否有 keepAlive 标志
      return this.$route.meta && this.$route.meta.keepAlive;
    },
  },
  methods: {
    loginOut() {
      this.$store.dispatch("logout");
    },
    handleLogin() {
      this.$store.dispatch("isLogin");
    },
    menuItem(item) {
      // console.log(item);
      this.$store.commit("homeData/setmenuKey", item.key);
    },
    handleCancel() {
      this.messageVisible = false;
    },
    //消息弹kuang已读
    handleRead() {
      // console.log(this.messageItemId);
      this.messageVisible = false;
      let itemId = {
        id: this.messageItemId,
      };
      // this.$services.message.readMessageInfo(itemId).then(() => {
      //   this.getMessage();
      // });
    },
    //消息展示
    showMessageModal(e, item) {
      this.messagesItemIndex = e;
      this.messageVisible = true;
      this.messageItemId = item.id;
      // console.log(e, item);
    },
    //已读
    messageButton(index) {
      // console.log(index);
      this.datahistory.splice(this.$refs.li[index], 1);
    },
    onClose() {
      this.visible = false;
    },
    //获取消息
    // getMessage() {
    // this.$services.message.queryUnReadMessageInfo().then((res) => {
    //   if (res.data.respCode == 200) {
    //     this.datahistory = res.data.data;
    //     // console.log(res.data.data);
    //   }
    // });
    // },
    showDrawer() {
      this.visible = true;
      this.getMessage();
    },
    onChange(e) {
      this.placement = e.target.value;
    },
    async logout() {
      await this.$store.dispatch("account/logout").then(() => {
        location.reload();
      });
    },
  },
};
</script>

<style lang="less" scoped>
html,
body,
#app {
  width: 100%;
  height: 100%;
  /*background: #cccccc;*/
}

.icon-white {
  color: #a0a0a0;
  font-size: 20px;
}

#components-layout-demo-top-side-2 {
  height: 100%;
}

.header {
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  z-index: 8;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  top: 0;
}

.header-box {
  width: 90%;
  height: 100%;
  /*border-bottom: 1px solid #dedfe6;*/
}

.logo {
  width: 23%;
  color: #1b73e8;
  font-weight: bold;
  /*font-family: "楷体";*/
  font-size: 25px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 0px 30px 0 0;
  float: left;
}

.herader-menu {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fff;
}

.content-end {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.herader-align-center {
  display: flex;

  align-items: center;
}

.title {
  font-size: 16px;
  /* padding: 0 25px; */
}

.header-right {
  width: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#components-layout-demo-top-side-2 .header .title .tiltle-item {
  color: #70a6f1;
  font-size: 16px;
}

.ant-layout {
  background: #fff;
  /*margin: auto;*/
}

.ant-menu .ant-menu-item-selected > a,
.ant-menu .ant-menu-item-selected > a:hover {
  color: #fff;
  font-weight: bold;
  border-bottom: 2px solid #70a6f1;
}
.ant-menu-horizontal > .ant-menu-item-selected,
.title .ant-menu-item .ant-menu-item-selected {
  background-color: #fff;
  border-bottom: none !important;
}
.ant-menu-horizontal .ant-menu-item-selected {
  border-bottom: none;
}

#components-layout-demo-top-side-2 .content {
  background: #fff;
  height: 96%;
  padding: 0 0;
  margin: 0;
}

.signOut {
  margin-left: 10px;
  margin-top: 1px;
  color: #e9dede;
  font-size: 14px;

}

.signOut:hover {
  color: #70a6f1;
}

.content-height {
  width: 99%;
  height: calc(100% - 80px);
  margin: auto;
  margin-top: 5px;
}

.ant-layout-footer {
  padding: 0 0 !important;
}

.box-footer {
  height: 30px; /**/
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  line-height: 30px;
  background: #f8f8ff;
  padding: 0 !important;
}

.message-content li {
  clear: both;
  margin-bottom: 20px;
}

.message-item {
  float: left;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-off {
  background: #70a6f1;
  border-radius: 5px;
  color: #ffffff;
  float: right;
}

.btn-read {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.message-read .ant-modal-content {
  /*height: 100%;*/
  min-height: 120px;
}
.login-button {
  color: black;
}
.login-button:hover {
  color: #70a6f1;
}

/* 取消a-menu的省略号 */
/deep/.ant-menu-submenu-title {
  overflow: visible !important; /* 覆盖默认的overflow样式 */
  display: none!important;
}

/deep/.ant-menu-submenu-title-content {
  text-overflow: inherit !important; /* 覆盖文字溢出样式 */
}

</style>