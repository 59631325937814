// import { resetRouter } from '@/router'
import { getToken, removeToken } from '@/services/cookie'
import services from '@/services'
// import { message } from 'ant-design-vue'


export default {
  namespaced: true,
  state: {
    token: getToken(),
    tokenName: "",
    user: {
      userId: '',
      userName: '',
      deptId: '',
      avatar: '',
      roles: [],
      roleIds: [],
      permissions: []
    }
  },
  getters: {
    getUser(state) {
      return state.user
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setToken(state, token) {
      // console.log(token)

      state.token = token.tokenValue;
      state.tokenName = token.tokenName;
    }
  },
  actions: {
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        // getInfo({ commit }) {
        //   return new Promise((resolve) => {
        let data = {
          userId: "admin",
          userName: "超级管理员",
          deptId: "00000000000000000000",
          avatar: "",
          roles: ["超级管理员"],
          roleIds: []
        };
        commit('setUser', data)
        commit('setToken', 'hasToken')

        resolve(data)
        // services.auth.getUserInfo({
        //   token: state.token
        // }).then(response => {
        //   let data = response.data.data;

        //   if (!data) {
        //     reject('验证失败，请重新登录！')
        //   }
        //   let userRoles = data.roles;
        //   let roleIds = [];
        //   let roles = []
        //   userRoles.forEach((item) => {
        //     roles.push(item.roleName);
        //     roleIds.push(item.roleId);
        //   });
        //   data.roles = roles;
        //   data.roleIds = roleIds;
        //   commit('setUser', data)
        //   resolve(data)
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },
    resetInfo({ commit }) {
      return new Promise(resolve => {
        commit('setUser', {
          userId: "",
          userName: "",
          deptId: "",
          avatar: '',
          roles: [],
          roleIds: [],
          permissions: []
        })
        commit('setToken', "")
        removeToken()
        resolve()
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('setUser', {
          userId: "",
          userName: "",
          deptId: "",
          avatar: '',
          roles: [],
          roleIds: [],
          permissions: []
        })
        commit('setToken', "")
        removeToken()
        // resetRouter()
        resolve()
      })
    },
  }
}
