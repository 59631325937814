<template>
  <div>
    <a-list
      :pagination="pagination"
      :data-source="list"
      :grid="{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }"
    >
      <a-list-item
        slot="renderItem"
        key="item.title"
        slot-scope="item, index"
        @click="handleAttractDetails(item)"
      >
        <a-row class="list-item">
          <div class="list-dept" v-if="item.canApplyToDebt_dictText == '是'">
            <img src="../../assets/img/dept.png" alt="" />
          </div>
          <div
            class="list-dept-font"
            v-if="item.canApplyToDebt_dictText == '是'"
          >
            <div>可用债权抵付</div>
          </div>
          <a-col :span="10" style="padding: 5px; overflow: hidden">
            <!-- <div>i</div> -->
            <img
              v-if="item.cover"
              class="list-img"
              alt="logo"
              :src="
                item.cover
                  ? $getFileUrl(item.cover.split(',')[0])
                  : '../../assets/img/asset_pic.jpg'
              "
            />
            <img
              v-else
              class="list-img"
              alt="logo"
              src="../../assets/img/asset_pic.jpg"
            />
          </a-col>
          <a-col :span="14" class="list-content">
            <a-row style="padding: 10px">
              <div style="height: 55px">
                <a class="item-title"
                  ><a-tooltip>
                    <template slot="title">{{ item.name }} </template>
                    {{ item.name }}
                  </a-tooltip></a
                >
              </div>

              <div style="margin-top: 10px">
                <a-icon type="home" /> 资产类型：{{ item.assetType_dictText }}
              </div>
              <div style="margin-top: 10px">
                <a-icon type="tags" /> 资源类型：{{
                  item.resourceType_dictText
                }}
              </div>
              <div style="margin-top: 10px">
                <a-icon type="history" /> 资产状态：{{
                  item.assetStatus_dictText
                }}
              </div>
            </a-row>
            <a-row class="list-buttom">
              <a-col class="list-buttom-red" :span="12"
                ><span>待运营</span>
                <!-- <span>还剩 &nbsp;</span
                ><a-statistic-countdown
                  :valueStyle="{
                    fontSize: '14px',
                    color: '#000',
                    lineHeight: '30px',
                  }"
                  :value="new Date(item.createTime).getTime()"
                  format="D 天 H 时 m 分 s 秒"
              /> -->
              </a-col>
              <a-col class="list-buttom-gray" :span="12"
                ><span>查看详情</span>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "AuctionList",
  props: ["list", "total", "current"],
  data() {
    return {
      //   listData,
      pagination: {
        // onChange: (page) => this.$parent.getNewsList(page),
        // current: 1, // 当前页数
        // total: 0,
        // pageSize: 5,
      },
    };
  },
  beforeUpdate() {
    this.pagination = {
      onChange: (page) => this.$emit("getTobeoperatingAssetsList", page),
      current: this.current, // 当前页数
      total: this.total,
      pageSize: 10,
    };
  },

  methods: {
    handleAttractDetails(record) {
      router.push({ path: "/awaiting", query: { id: record.id } });
      //  router.push({ path: "/operate", query: { id: record.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.list-item {
  position: relative;
  margin-left: 10px;
  background-color: #fff;
  // border: 1px #eaedf2 solid;
  border: none;
  border-radius: 15px;
  color: #fff;
  height: 210px;
  // overflow: hidden;
  .list-dept {
    z-index: 2;
    position: absolute;
    top: 5px;
    left: -12px;
  }
  .list-dept-font {
    font-size: 16px;
    z-index: 3;
    position: absolute;
    top: 20px;
    left: 17px;
  }
  .list-img {
    // margin: 5px 5px 0px 5px;
    transition: transform 0.5s ease;
    border-radius: 15px;
    height: 200px;
    width: 100%;
  }
  .list-img:hover {
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); /* 放大到1.5倍 */
  }

  .list-content {
    color: #000;
    position: relative;
    height: 210px;
    .list-buttom {
      width: 100%;
      height: 30px;
      color: #fff;
      text-align: center;
      line-height: 30px;
      position: absolute;
      bottom: 0;
      .list-buttom-red {
        display: flex;
        justify-content: center;
        height: 30px;
        border-top-left-radius: 15px;
        color: #000;
        background-color: #e7e7e7;
      }
      .list-buttom-gray {
        font-weight: 700;
        height: 30px;
        border-bottom-right-radius: 15px;
        background-color: #3d3d3d;
      }
    }
  }
}
.item-title {
  color: #000;
  font-weight: bold;
  font-size: 17px;
}
.list-item:hover {
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2),
    /* 底部阴影 */ 0 5px 5px rgba(0, 0, 0, 0.2),
    /* 左侧阴影 */ -5px 0 5px rgba(0, 0, 0, 0.2),
    /* 右侧阴影 */ 5px 0 5px rgba(0, 0, 0, 0.2);
  /* background-color: rgb(188, 228, 235); */
}
</style>