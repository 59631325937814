import axios from "../../services/request";

const user = {
  state: {
    token: "",
    userInfo: {},
    sysAllDictItems: {},
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/sso/doLoginByTicket", { params: data })
          .then((response) => {
            console.log("----cas 登录--------", response);
            if (response.success) {
              const result = response.result;
              const userInfo = result.userInfo;
              window.localStorage.setItem("token", result.token);
              window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
              // window.localStorage.setItem("sysAllDictItems", JSON.stringify(sysAllDictItems));

              commit("SET_TOKEN", result.token);
              commit("SET_USER_INFO", userInfo);
              resolve(response);
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    isLogin({ state }) {
      console.log(state.token);
      if (state.token) {
        return true;
      }
      let redirect = encodeURIComponent(`http://${window.location.host}/`);
      console.log(redirect);
      console.log(process.env.VUE_APP_SSO_BASE_URL);
      window.location.href = `${process.env.VUE_APP_SSO_BASE_URL}/login?redirect=${redirect}`;
    },
    loginExpire() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userInfo");
      let redirect = encodeURIComponent(`${window.location.href}`);
      window.location.href = `${process.env.VUE_APP_SSO_BASE_URL}/sso/auth?redirect=${redirect}`;
      // let redirect = encodeURIComponent(`http://${window.location.host}/`);
    },
    logout({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/sys/logout", { params: data })
          .then((response) => {
            console.log("----cas 登录--------", response);
            if (response.success) {
              // dispatch("isJump");
              let redirect = encodeURIComponent(
                `http://${window.location.host}/`
              );
              window.localStorage.removeItem("token");
              window.localStorage.removeItem("userInfo");
              window.location.href = `${process.env.VUE_APP_SSO_BASE_URL}/sso/signout?back=${redirect}`;
              resolve(response);
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    isJump({ state }) {
      // `${process.env.VUE_APP_SSO_BASE_URL}/sso/logout?redirect=${redirect}`

      let redirect = encodeURIComponent(`http://${window.location.host}/`);
      window.location.href = `${process.env.VUE_APP_SSO_BASE_URL}?redirect=${redirect}`;
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
  },
};

export default user;
