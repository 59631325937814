/** 
 * api接口的统一出口
 */

// 登录模块接口

import news from './modules/news';






// 导出接口
export default {
    news,
}