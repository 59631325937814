<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view> </router-view>
      <!-- <router-view name="bottom"></router-view> -->
    </a-config-provider>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      locale: {},
    };
  },
  created() {
    this.locale = require("ant-design-vue/es/locale-provider/zh_CN").default;
  },
};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column; /* 确保子元素垂直排列 */
}
</style>
