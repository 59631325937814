import { constantRoutes } from '@/router'

import OverallSituationLayout from '@/layout/OverallSituationLayout'
// import services from '@/services'
// import store from '../index'

export const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/pages/${view}`], resolve)
}

export function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(route => {
    if (route.component) {
      route.component = loadView(route.component)
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }) {
    return new Promise(resolve => {
      // 向后端请求路由数据
      // console.log(account)
      // services.auth.getUserMenuTree({ userId: store.state.account.user.userId }).then(res => {
      //   const rst = res.data.data

      const rst = [
        // {
        //   path: '/',
        //   redirect: '/home',
        //   name: "载入页",
        //   children: [
        //     {
        //       path: 'home',
        //       name: '系统首页',
        //       meta: {
        //         icon: 'home'
        //       },
        //       component: () => import('@/pages/home/HomePage')
        //     },
        //     {
        //       path: 'asset',
        //       name: '资产',
        //       meta: {
        //         icon: 'folder'
        //       },
        //       component: () => import('@/pages/asset/AssetManagement')
        //     },
        //   ]
        // }
        {
          "path": "/home",
          "component": "home/HomePage",
          "name": "首页",
          meta: { showInNav: true }
        },

        {
          "path": "/asset",
          "component": "asset/AssetManagement",
          "name": "资产",
          meta: { showInNav: true }
        }, {
          "path": "/auction",
          "component": "auction/AuctionPage",
          "name": "拍卖会",
          meta: { showInNav: true }
        }, {
          "path": "/trading",
          "component": "trading/TransactionAnnouncement",
          "name": "交易公告",
          meta: { showInNav: true }
        }, {
          "path": "/investment",
          "component": "investment/InvestmentPromotionCenter",
          "name": "招商中心",
          meta: { showInNav: true }
        }, {
          "path": "/news",
          "component": "news/NewsCenter",
          "name": "新闻中心",
          meta: { showInNav: true }
        }, {
          "path": "/policy",
          "component": "policy/PoliciesAndRegulations",
          "name": "政策法规",
          meta: { showInNav: true }

        }, {
          "path": "/about",
          "component": "about/AboutUs",
          "name": "关于我们",
          meta: { showInNav: true }
        }, {
          "path": "/help",
          "component": "help/HelpCenter",
          "name": "帮助中心",
          meta: { showInNav: true }
        },
        {
          "path": "/article",
          "component": "article/ArticleDetail",
          "name": "文章详情",
          meta: { showInNav: false }
        }, {
          "path": "/apply",
          "component": "apply/ApplyAgency",
          "name": "评估申请",
          meta: { showInNav: false }
        }, {
          "path": "/operate",
          "component": "asset/AssetAwaitingDetails",
          "name": "代运营资产",
          meta: { showInNav: false }
        },
      ]


      const tempAsyncRouterMap = rst.map(route => {
        if (route.children && route.children.length > 0) {
          route.redirect = '/' + route.path + '/' + route.children[0].path
        }

        return route
      })

      const filterAsyncRoutes = filterAsyncRouter(tempAsyncRouterMap);

      const accessedRoutes =
      {
        path: '/',
        redirect: '/home',
        name: '分页',
        component: OverallSituationLayout,
        children: filterAsyncRoutes
      }

      console.log(accessedRoutes)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
