import axios from "axios";
import { message } from "ant-design-vue";
import store from "../store";
const showFailToast = (options) => {
  let r = {
    content: options.message,
    onClose: options.onClose,
  };
  message.error(r);
};

// import useUserStore from '@/stores/user';

// const userStore = useUserStore();
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
// const apiBaseUrl = 'http://47.115.221.152:7004';
console.log("apiBaseUrl=", apiBaseUrl);

const service = axios.create({
  baseURL: apiBaseUrl,
  timeout: 5000,
  headers: {
    "X-Client-Header": "all",
  },
});

service.interceptors.request.use(
  (config) => {
    const token = store.state.user.token;
    if (token) {
      config.headers["X-Access-Token"] = token;
    }else{
      config.headers["X-Access-Token"] = "";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // console.log(error);
    if (error.response) {
      const data = error.response.data;
      switch (error.response.status) {
        case 401:
          showFailToast({
            message: "登录已过期，请重新登录",
            onClose() {
              store.dispatch("loginExpire");
            },
          });
          break;
        case 403:
          showFailToast("拒绝访问");
          break;
        case 404:
          showFailToast("很抱歉，资源未找到!");
          break;
        case 500:
          if (token && data.message.includes("Token失效")) {
            showFailToast({
              message: "登录已过期，请重新登录",
              onClose() {
                store.dispatch("loginExpire");
              },
            });
          }
          break;
        case 504:
          showFailToast("网络超时");
          break;
        default:
          showFailToast(data.message);
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;

// import axios from 'axios'
// import router from '../router'
// import store from '../store';
// import { message } from 'ant-design-vue'

// /**
//  * 提示函数
//  * 禁止点击蒙层、显示一秒后关闭
//  */
// const tip = msg => {
// 	message.error(msg);
// }

// /**
//  * 跳转登录页
//  * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
//  */
// const toLogin = () => {
// 	router.replace({
// 		path: '/login',
// 		query: {
// 			redirect: router.currentRoute.fullPath
// 		}
// 	});
// }

// /**
//  * 请求失败后的错误统一处理
//  * @param {Number} status 请求失败的状态码
//  */
// // const errorHandle = (status, other) => {
// const errorHandle = (status) => {
// 	// 状态码判断
// 	switch (status) {
// 		// 401: 未登录状态，跳转登录页
// 		case 401:
// 			toLogin();
// 			break;
// 		// 404请求不存在
// 		case 404:
// 			tip('请求的资源不存在');
// 			break;
// 		/*default:
// 			tip(other);  */
// 	}
// }

// // 创建axios实例
// var instance = axios.create({ timeout: 10000 * 60 });

// /**
//  * 请求拦截器
//  * 每次请求前，如果存在token则在请求头中携带token
//  */
// instance.interceptors.request.use(
// 	config => {
// 		// 登录流程控制中，根据本地是否存在token判断用户的登录情况
// 		// 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
// 		// 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
// 		// 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
// 		const token = store.state.account.token;
// 		token && (config.headers.satoken = token) && (config.headers.Authorization = token);
// 		return config;
// 	},
// 	error => Promise.error(error)
// )

// // 响应拦截器
// instance.interceptors.response.use(
// 	// 请求成功
// 	res => {

// 		if (res.data.respCode === 401) {
// 			// router.push({ path: "/login" })
// 			store.dispatch("account/logout", "token").then(() => {
// 				location.reload();
// 			});

// 		}
// 		return res.status === 200 ? Promise.resolve(res) : Promise.reject(res)
// 	},

// 	// 请求失败
// 	error => {
// 		const { response } = error;
// 		if (response) {
// 			// 请求已发出，但是不在2xx的范围
// 			errorHandle(response.status, response.data.message);
// 			return Promise.reject(response);
// 		}
// 	}
// );

// export default instance;
