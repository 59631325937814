/**
 * 登陆模块接口列表
 */

// 导入接口域名列表
import axios from '../request';    			// 导入request中创建的axios实例

const news = {

	//分类导航
	getNavigation(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/navigation/${params}`);
	},
	//获取轮播图
	getBanner(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/banner`, { params });
	},
	//公告分页列表
	getAnnouncementList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/announcement`, { params });
	},
	//最新公告
	getLastAnnouncementList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/lastAnnouncement`, { params });
	},
	//新闻列表
	getNewsList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/news`, { params });
	},
	//最新新闻
	getLastNewsList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/lastNews`, { params });
	},
	//政策列表
	getPolicyList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/policy`, { params });
	},
	//最新政策
	getLastPolicyList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/lastpolicy`, { params });
	},
	//公告列表
	getAnnouncementList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/announcement`, { params });
	},
	//最新公告
	getLastAnnouncementList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/lastAnnouncement`, { params });
	},
	//招标列表
	getTenderList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/tenderAnnouncement`, { params });
	},
	//文章详情
	getArticleDetails(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/cms/article`, { params });
	},

	//获取静态资源
	getStaticFilePreviewUrl(path) {
		return `${process.env.VUE_APP_API_BASE_URL}${process.env.VUE_APP_STATIC_PATH}/${path}`;
	},

	//招商分页列表
	getCourtshipAssetsList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/atpCourtshipAssetsList`, { params });
	},
	//招商详情查询
	getCourtshipAssetsById(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/queryAtpCourtshipAssetsById`, { params });
	},
	//招商意向信息表添加
	addAtpIntentionInfo(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/open/api/addAtpIntentionInfo`, params);
	},
	//待运营分页列表
	getTobeoperatingAssetsList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/tobeoperatingAssets/list`, { params });
	},
	//待运营详情查询
	getTobeoperatingAssetsById(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/tobeoperatingAssets/queryById`, { params });
	},
	//待运营统计查询
	getReportStatistics(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/tobeoperatingAssets/reportStatistics`, { params });
	},
	//待运营意向信息表添加
	addtobeoperatingAssetsInfo(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/open/api/tobeoperatingAssets/add`, params);
	},
	//查询分类字典
	getcategoryItem(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/global/categoryItem`, { params });
	},
	//获取验证码
	getRandomImage(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/global/randomImage/${params}`);
	},
	//发送手机号验证码请求
	postGlobalSms(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/open/api/global/sms`, params);
	},
	//资产分页列表
	getAssetsList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/assets/list`, { params });
	},
	//资产详情查询
	getAssetsById(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/assets/queryById`, { params });
	},
	//拍卖会分页列表
	getAuctionList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/auction/list`, { params });
	},
	//拍卖会详情查询
	getAuctionById(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/auction/queryById`, { params });
	},
	//拍卖会详情查询
	getAuctionAssetsList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/auction/assetsList`, { params });
	},
	//拍卖报名
	paymentSignUp(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/deposit/payment/signUp`, { params });
	},
	//保证金退款申请
	paymentRefundApply(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/deposit/payment/refundApply`, { params });
	},
	//我的保证金分页 
	getMyDeposit(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/myDeposit`, { params });
	},
	//上传凭证 
	paymentEdit(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/deposit/payment/edit`, params, { 'responseType': 'blob' });
	},
	//上传文件
	commonUpload(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/sys/common/upload`, params, { 'responseType': 'blob' });
	},
	//获取保证金信息
	getDepositPaymentById(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/deposit/payment/getDepositPaymentById`, { params });
	},
	//获取保证金缴纳信息
	getDepositPaymentByVo(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/deposit/payment/getDepositPaymentByVo`, { params });
	},
	//资产成交记录
	assetsRank(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/assets/rank`, { params });
	},
	//获取竞买号
	getBidNumber(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/deposit/payment/getBidNumber`, { params });
	},
	//获取竞买号和优先购买权信息
	getBidInfo(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/deposit/payment/getBidInfo`, { params });
	},
	//举牌报价
	personalBid(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/personal/bid`, params);
	},
	//行使优先购买权
	execAbsolutePurchaseRights(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/personal/execAbsolutePurchaseRights`, params);
	},
	//我的竞拍
	getmyAuction(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/myAuctionTwo`, { params });
	},
	//我的竞拍详情
	queryRunAuctionById(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/queryRunAuctionById`, { params });
	},
	//我的竞价记录
	queryAuctionById(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/queryAtpRunAuctionRecordByMainId`, { params });
	},
	//个人中心-根据主表ID查询所选凭证
	settlementVoucherList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/querySettlementVoucherInfoListByMainId`, { params });
	},
	//个人中心-根据主表ID保存所选凭证列表
	saveVoucherInfoList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/saveSettlementVoucherInfoList`, { params });
	},
	//个人中心-用户推荐资产
	interactionRecommend(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/interaction/recommend`, { params });
	},
	//个人中心-关注和取关资产
	interactionRegard(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/interaction/regard`, { params });
	},
	//个人中心-用户关注资产
	interactionConcerned(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/interaction/concerned`, { params });
	},
	//个人中心-我的关注
	getAttentionList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/myAttention`, { params });
	},
	//添加关注 
	addAttention(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/addAttention`, { params });
	},
	//取消关注 
	cancelAttention(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/cancelAttention`, { params });
	},
	//评估机构申请
	evaluationAgencyApply(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/open/api/evaluationAgency/apply`, params);
	},
	//评估机构列表查询
	getAgencyList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/evaluationAgency/list`, { params });
	},
	//个人中心-我的凭证查询
	getvoucherList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/voucherList`, { params });
	},
	//个人中心-我的结算
	getSettlementList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/queryMyCapitalSettlementPageList`, { params });
	},
	//个人中心-我的结算上传凭证
	editCapitalSettlement(params) {
		return axios.post(`${process.env.VUE_APP_API_BASE_URL}/personal/editCapitalSettlement`, params);
	},
	//个人中心-我的合同
	getConfirmationList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/queryMyTransactionConfirmationPageList`, { params });
	},
	//个人中心-我的交接
	getHandoverList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/myHandover`, { params });
	},
	//个人中心-我的消息
	getMessageList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/myMessage`, { params });
	},
	//个人中心-是否关注
	isAttention(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/isAttention`, { params });
	},
	//个人中心-我的凭证余额
	queryMyVoucherAmount(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/personal/queryMyVoucherAmount`, { params });
	},
	//首页全局搜索 
	searchList(params) {
		return axios.get(`${process.env.VUE_APP_API_BASE_URL}/open/api/assets/searchList`, { params });
	},
}

export default news;
