<template>
  <div class="main-height">
    <div class="main-banner">
      <a-carousel arrows :dots="false">
        <div
          slot="prevArrow"
          class="custom-slick-arrow"
          style="left: 10px; z-index: 1"
        >
          <a-icon class="banner-button" type="left-circle" />
        </div>
        <div
          slot="nextArrow"
          class="custom-slick-arrow"
          style="right: 30px; z-index: 1"
        >
          <a-icon class="banner-button" type="right-circle" />
        </div>
        <div v-for="banner in bannerList" :key="banner.id">
          <img
            class="main-banner"
            :src="$getFileUrl(banner.picture)"
            :alt="banner.title"
          />
        </div>
      </a-carousel>
    </div>
    <div class="main-content">
      <div class="main-title">
        <dir class="main-head-title">
          <div class="head-title">
            <!-- <div class="vertical-line"></div> -->
            <div class="vertical-title">资产资源重组专区</div>
          </div>

          <!-- <div class="vertical-more" @click="handleMore('/apply')">申请</div> -->
        </dir>
        <div class="horizontal-line-yellow"></div>
        <div class="horizontal-line-long"></div>
      </div>

      <div class="main-quick">
        <div
          v-for="speed in speedList"
          :key="speed.title"
          class="quick-entrance"
          @mouseenter="handleMouseOver(speed)"
          @mouseleave="handleMouseOver(speed)"
        >
          <div
            v-if="speed.title != '流转平台' && speed.title != '商城平台'"
            @click="handleMore('/asset', speed.code)"
          >
            <div>
              <img width="50px" height="50px" :src="speed.icon1" alt="" />
            </div>
            <div style="margin-top: 5px">{{ speed.title }}</div>
          </div>
          <div v-if="speed.title == '流转平台'" @click="handleJump('流转平台')">
            <div>
              <img width="50px" height="50px" :src="speed.icon1" alt="" />
            </div>
            <div style="margin-top: 5px">{{ speed.title }}</div>
          </div>
          <div v-if="speed.title == '商城平台'" @click="handleJump('商城平台')">
            <div>
              <img width="50px" height="50px" :src="speed.icon1" alt="" />
            </div>
            <div style="margin-top: 5px">{{ speed.title }}</div>
          </div>
        </div>
      </div>

      <div class="fjsb mt40">
        <div>
          <div class="table-title main-table">
            <div>
              <img
                class="table-title-img"
                src="@/assets/icon/拍卖会.png"
                alt=""
              /><span class="table-title-text">拍卖会</span>
            </div>
            <div @click="handleMore('/auction')" class="table-title-more">
              更多<a-icon type="right" />
            </div>
          </div>
          <div class="main-table">
            <a-row>
              <a-table
                :columns="auctionColumns"
                :data-source="auctionList"
                :customRow="(record) => customRow(record, '/auctionDetail')"
                :pagination="false"
                size="middle"
              >
                <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
                <span slot="reservePrice" slot-scope="record"
                  ><span class="red-title">{{ record.reservePrice }}</span
                  >元</span
                >
                <span slot="assetCount" slot-scope="record"
                  ><span class="red-title">{{ record.assetCount }}</span
                  >个</span
                >
              </a-table>
            </a-row>
          </div>
        </div>
        <div>
          <div class="main-tool">
            <span class="tool-title">常见问题</span>
          </div>
          <div class="tool-content">
            <div
              class="tool-box"
              v-for="help in helpList"
              :key="help.title"
              @click="handleMore('/help', help.key)"
            >
              <img class="tool-img" :src="help.icon1" alt="" />
              <span class="tool-text">{{ help.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fjsb mt40">
        <div>
          <div>
            <div class="table-title main-table">
              <div>
                <img
                  class="table-title-img"
                  src="@/assets/icon/资产招商.png"
                  alt=""
                /><span class="table-title-text">招商中心</span>
              </div>
              <div @click="handleMore('/investmet')" class="table-title-more">
                更多<a-icon type="right" />
              </div>
            </div>
            <div class="main-table">
              <a-row>
                <a-table
                  :columns="investmetColumns"
                  :data-source="investmentList"
                  :customRow="(record) => customRow(record, '/attract')"
                  :pagination="false"
                  size="middle"
                >
                  <span slot="courtshipStatus_dictText" slot-scope="record"
                    ><span class="red-title">{{
                      record.courtshipStatus_dictText
                    }}</span></span
                  >
                  <span slot="courtshipType_dictText" slot-scope="record"
                    ><span style="font-weight: 700">{{
                      record.courtshipType_dictText
                    }}</span></span
                  >
                </a-table>
              </a-row>
            </div>
          </div>
          <div class="mt20">
            <div class="table-title main-table">
              <div>
                <img
                  class="table-title-img"
                  src="@/assets/icon/资产招商.png"
                  alt=""
                /><span class="table-title-text">上拍资产</span>
              </div>
              <div @click="handleMore('/asset')" class="table-title-more">
                更多<a-icon type="right" />
              </div>
            </div>
            <div class="main-table">
              <a-row>
                <a-table
                  :columns="assetColumns"
                  :data-source="assetList"
                  :customRow="(record) => customRow(record, '/assetDetail')"
                  :pagination="false"
                  size="middle"
                >
                  <span slot="reservePrice" slot-scope="record"
                    ><span class="red-title">{{ record.reservePrice }}</span
                    >元</span
                  >
                </a-table>
              </a-row>
            </div>
          </div>
          <div class="mt20">
            <div class="table-title main-table">
              <div>
                <img
                  class="table-title-img"
                  src="@/assets/icon/资产招商.png"
                  alt=""
                /><span class="table-title-text">待运营资产</span>
              </div>
              <div @click="handleMore('/operate')" class="table-title-more">
                更多<a-icon type="right" />
              </div>
            </div>
            <div class="main-table">
              <a-row>
                <a-table
                  :columns="operateColumns"
                  :data-source="tobeoperatingList"
                  :customRow="(record) => customRow(record, '/awaiting')"
                  :pagination="false"
                  size="middle"
                >
                  <a slot="name" slot-scope="text">{{ text }}</a>
                </a-table>
              </a-row>
            </div>
          </div>
        </div>
        <div>
          <div class="main-tool">
            <span class="tool-title">合作项目推荐</span>
          </div>
          <div class="cooperate-content">
            <div
              class="cooperate-box"
              v-for="cooperate in cooperateList"
              :key="cooperate.title"
            >
              <img class="cooperate-img" :src="cooperate.icon1" alt="" />
              <span class="cooperate-text">{{ cooperate.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <a-row class="center-banner">
        <div class="center-title">常见问题</div>
        <div class="center-list">
          <div class="hexagon" @click="handleMore('/help', '资产交易流程规范')">
            <svg width="120" height="160" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="dropShadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                  <feOffset dx="2" dy="2" result="offsetblur" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <polygon
                points="60,0 120,30 120,120 60,160 0,120 0,30"
                fill="#fff"
                filter="url(#dropShadow)"
              />
              <g>
                <image
                  href="@/assets/icon/交易流程.png"
                  x="35"
                  y="-10"
                  width="50"
                  height="150"
                />
                <text x="60" y="100" class="diamond-text" dy=".35em">
                  交易流程
                </text>
              </g>
            </svg>
          </div>
          <div class="hexagon" @click="handleMore('/help', '平台收费标准')">
            <svg width="120" height="160" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="dropShadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                  <feOffset dx="2" dy="2" result="offsetblur" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <polygon
                points="60,0 120,30 120,120 60,160 0,120 0,30"
                fill="#fff"
                filter="url(#dropShadow)"
              />
              <g>
                <image
                  href="@/assets/icon/收费标准.png"
                  x="35"
                  y="-10"
                  width="50"
                  height="150"
                />
                <text x="60" y="100" class="diamond-text" dy=".35em">
                  收费标准
                </text>
              </g>
            </svg>
          </div>
          <div class="hexagon" @click="handleMore('/help', '出价竞拍')">
            <svg width="120" height="160" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="dropShadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                  <feOffset dx="2" dy="2" result="offsetblur" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <polygon
                points="60,0 120,30 120,120 60,160 0,120 0,30"
                fill="#fff"
                filter="url(#dropShadow)"
              />
              <g>
                <image
                  href="@/assets/icon/出价规则.png"
                  x="35"
                  y="-10"
                  width="50"
                  height="150"
                />
                <text x="60" y="100" class="diamond-text" dy=".35em">
                  出价规则
                </text>
              </g>
            </svg>
          </div>
          <div class="hexagon" @click="handleMore('/help', '竞拍成功')">
            <svg width="120" height="160" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="dropShadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                  <feOffset dx="2" dy="2" result="offsetblur" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <polygon
                points="60,0 120,30 120,120 60,160 0,120 0,30"
                fill="#fff"
                filter="url(#dropShadow)"
              />
              <g>
                <image
                  href="@/assets/icon/成交规则.png"
                  x="35"
                  y="-10"
                  width="50"
                  height="150"
                />

                <text x="60" y="100" class="diamond-text" dy=".35em">
                  成交规则
                </text>
              </g>
            </svg>
          </div>
          <div class="hexagon" @click="handleMore('/help', '资产交易委托书')">
            <svg width="120" height="160" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="dropShadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                  <feOffset dx="2" dy="2" result="offsetblur" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <polygon
                points="60,0 120,30 120,120 60,160 0,120 0,30"
                fill="#fff"
                filter="url(#dropShadow)"
              />
              <g>
                <image
                  href="@/assets/icon/下载中心.png"
                  x="35"
                  y="-10"
                  width="50"
                  height="150"
                />
                <text x="60" y="100" class="diamond-text" dy=".35em">
                  下载中心
                </text>
              </g>
            </svg>
          </div>
        </div>
      </a-row> -->
    <div class="news-banner">
      <div class="main-content">
        <div class="main-title">
          <div class="main-head-title">
            <div class="head-title">
              <div class="vertical-line"></div>
              <div class="vertical-title">新闻中心</div>
            </div>

            <div class="vertical-more" @click="handleMore('/news')">更多</div>
          </div>
          <div class="horizontal-line"></div>
          <div class="horizontal-line-long"></div>
        </div>
        <div class="main-substance">
          <div class="news-substance">
            <a-carousel :dots="false" arrows>
              <div
                slot="prevArrow"
                slot-scope="props"
                class="custom-slick-arrow"
                style="left: 10px; top: 175px; z-index: 1"
              >
                <a-icon class="banner-button-news" type="left-circle" />
              </div>
              <div
                slot="nextArrow"
                slot-scope="props"
                class="custom-slick-arrow"
                style="right: 10px; top: 175px"
              >
                <a-icon class="banner-button-news" type="right-circle" />
              </div>
              <div v-for="news in lastNewsListData" :key="news.id">
                <img
                  @click="handleArticleDetail(lastNewsListData[0])"
                  :src="$getFileUrl(news.photos)"
                  :alt="news.title"
                  v-if="news.photos"
                />
                <img
                  @click="handleArticleDetail(lastNewsListData[0])"
                  src="../../assets/img/tender.jpeg"
                  :alt="news.title"
                  v-else
                />
                <div class="news-banner-title">{{ news.title }}</div>
              </div>
            </a-carousel>
          </div>
          <div class="news-content">
            <div
              class="news-recommend"
              @click="handleArticleDetail(lastNewsListData[0])"
            >
              <div class="news-title-first">
                {{ lastNewsListData[0] && lastNewsListData[0].title }}
              </div>
              <div class="news-introduce">
                {{ lastNewsListData[0] && lastNewsListData[0].summary }}
              </div>
              <div class="news-item">
                <div class="news-source">
                  发布人{{
                    lastNewsListData[0] && lastNewsListData[0].publisher
                  }}
                </div>
                <div class="news-time">
                  {{ lastNewsListData[0] && lastNewsListData[0].createTime }}
                </div>
              </div>
            </div>
            <div class="news-list">
              <div
                class="new-title"
                v-for="(news, index) in lastNewsListData"
                :key="news.id"
                @click="handleArticleDetail(news)"
              >
                <div v-if="index == 0" class="news-head">
                  <span class="news-dot"> </span>
                  <span class="red-title">
                    {{ news.title }}
                  </span>
                </div>
                <div v-else class="news-head">
                  <span class="news-dot"> </span>
                  <span>
                    {{ news.title }}
                  </span>
                </div>
                <div class="news-time">
                  {{ news.createTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="main-title">
        <dir class="main-head-title">
          <div class="head-title">
            <div class="vertical-line"></div>
            <div class="vertical-title">结果公示</div>
          </div>

          <!-- <div class="vertical-more" @click="handleMore('/news')">更多</div> -->
        </dir>
        <div class="horizontal-line"></div>
        <div class="horizontal-line-long"></div>
      </div>
      <div class="main-result">
        <div
          class="main-result-content"
          v-for="(item, index) in announcementListData"
          :key="index"
          @click="handleArticleDetail(item)"
        >
          <div class="result-box">
            <div class="result-group">
              <span class="result-time-dd">{{
                moment(item.createTime).format("DD")
              }}</span>
              <span class="result-time-yy">{{
                moment(item.createTime).format("YYYY-MM")
              }}</span>
            </div>
          </div>
          <div class="result-content">
            <div class="result-title">
              {{ item.title }}
            </div>
            <div class="result-text">
              <span> 起拍价：{{ item.extend2 }}{{ item.extend4 }} </span>
              <span> 成交价： {{ item.extend3 }}{{ item.extend4 }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply-background">
      <div class="main-content">
        <div class="main-title">
          <dir class="main-head-title">
            <div class="head-title">
              <div class="vertical-line"></div>
              <div class="vertical-title">评估机构</div>
            </div>

            <div class="vertical-more" @click="handleMore('/apply')">申请</div>
          </dir>
          <div class="horizontal-line"></div>
          <div class="horizontal-line-long"></div>
        </div>

        <div class="main-apply">
          <VueSlickCarousel v-if="agencyData.length" v-bind="settings">
            <div v-for="item in agencyData" :key="item.value">
              <div class="apply-banner">{{ item.orgName }}</div>
            </div>
          </VueSlickCarousel>
          <!-- <VueSlickCarousel v-if="agencyData.length" v-bind="settings">
          <div
            class="apply-group"
            v-for="item in agencyData"
            :key="item.orgName"
          >
            <div class="apply-box">
              <div class="apply-text">
                <span>企业logo</span>
              </div>
            </div>
            <div class="apply-name">
              <span>{{ item.orgName }}</span>
            </div>
          </div>
        </VueSlickCarousel> -->
        </div>
      </div>

      <!-- <div class="main-content"></div> -->
    </div>
  </div>
</template>

<script>
import AuctioinList from "../auction/AuctionList.vue";
import AssetList from "../asset/AssetList.vue";
import AwaitingList from "../operate/AwaitingList.vue";
import InvestmentList from "../investment/InvestmentList.vue";
import router from "@/router";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import moment from "moment";

export default {
  name: "homePage",
  components: {
    AuctioinList,
    AssetList,
    InvestmentList,
    AwaitingList,
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        // accessibility: true,
        // centerPadding: "50px",
        // dots: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
      lastNewsListData: [],
      newsActiveKey: "",
      policyActiveKey: "",
      newsList: [],
      policyList: [],
      policyListData: [],
      tenderData: [
        {
          title: "工程类",
          value: "669",
          type: "build",
          unit: "个",
        },
        {
          title: "服务类",
          value: "669",
          type: "smile",
          unit: "个",
        },
        {
          title: "货物类",
          value: "669",
          type: "tool",
          unit: "个",
        },
        {
          title: "其他类",
          value: "669",
          type: "reconciliation",
          unit: "个",
        },
      ],
      data: [
        {
          title: "总资产数",
          value: "669",
          unit: "项目",
        },
        {
          title: "总建筑面积",
          value: "2277453",
          unit: "平米",
        },
        {
          title: "总预评估价格",
          value: "3258",
          unit: "万元",
        },
      ],
      mechanismData: [
        { src: "../../assets/img/mechanism1.png" },
        { src: "../../assets/img/mechanism2.png" },
        { src: "../../assets/img/mechanism3.png" },
        { src: "../../assets/img/mechanism4.png" },
      ],
      agencyData: [
        // { value: "内蒙古弘泰资产评估事务所有限公司" },
        // { value: "包头市中正价格评估有限责任公司 " },
        // { value: " 内蒙古诚大资产评估事务所 " },
        // { value: " 内蒙古杰信达华资产评估有限公司 " },
        // { value: " 内蒙古泓鑫资产评估事务所 " },
        // { value: " 中联资产评估集团有限公司 " },
        // { value: "北京中天华资产评估有限责任公司" },
        // { value: "北京卓信大华资产评估有限公司" },
      ],
      reportStatisticsData: [
        {
          title: "总资产数",
          value: "",
          unit: "项目",
        },
        {
          title: "总建筑面积",
          value: "",
          unit: "平米",
        },
        {
          title: "总预评估价格",
          value: "",
          unit: "万元",
        },
      ],
      auctionColumns: [
        {
          title: "资产名称",
          dataIndex: "name",
          align: "left",
          width: "30%",
          ellipsis: true,
        },

        {
          title: "拍卖公司",
          dataIndex: "auctionUnit",
          align: "center",
          ellipsis: true,
        },
        {
          title: "起拍价格",
          // dataIndex: "reservePrice",
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "reservePrice" },
        },
        {
          title: "资产数量",
          // dataIndex: "assetCount",
          align: "center",
          scopedSlots: { customRender: "assetCount" },
          ellipsis: true,
        },
        {
          title: "竞价开始时间",
          dataIndex: "registrationEndTime",
          align: "center",
          ellipsis: true,
        },
      ],
      investmetColumns: [
        {
          title: "招商资产名称",
          dataIndex: "courtshipName",
          align: "left",
          width: "30%",
          ellipsis: true,
        },

        {
          title: "招商状态",
          // dataIndex: "courtshipStatus_dictText",
          scopedSlots: { customRender: "courtshipStatus_dictText" },
          align: "center",
          ellipsis: true,
        },
        {
          title: "招商类型",
          // dataIndex: "courtshipType_dictText",
          scopedSlots: { customRender: "courtshipType_dictText" },
          align: "center",
          ellipsis: true,
        },
        {
          title: "是否可用化债",
          dataIndex: "canSupportBondOffset",
          align: "center",
          ellipsis: true,
        },
        {
          title: "招商开始时间",
          dataIndex: "createTime",
          align: "center",
          ellipsis: true,
        },
      ],
      assetColumns: [
        {
          title: "资产名称",
          align: "left",
          dataIndex: "assetName",
          ellipsis: true,
        },
        {
          title: "资产编号",
          align: "center",
          dataIndex: "assetNumber",
        },

        {
          title: "上拍价格",
          align: "center",
          // dataIndex: "reservePrice",
          scopedSlots: { customRender: "reservePrice" },
        },
        {
          title: "是否可用化债",
          dataIndex: "canApplyToDebt_dictText",
          align: "center",
          ellipsis: true,
        },
        {
          title: "竞价开始时间",
          dataIndex: "biddingStartTime",
          align: "center",
          ellipsis: true,
        },
      ],
      operateColumns: [
        {
          title: "资产名称",
          align: "left",
          dataIndex: "name",
          ellipsis: true,
        },
        {
          title: "资产类型",
          align: "center",
          dataIndex: "assetType_dictText",
        },
        {
          title: "资源类型",
          align: "center",
          dataIndex: "resourceType_dictText",
        },
        {
          title: "是否可用化债",
          dataIndex: "canApplyToDebt_dictText",
          align: "center",
          ellipsis: true,
        },
        {
          title: "资产状态",
          dataIndex: "assetStatus_dictText",
          align: "center",
          ellipsis: true,
        },
      ],
      tobeoperatingList: [],
      tobeoperatingTotal: 0,
      tobeoperatingCurrent: 0,
      investmentList: [],
      investmentTotal: 0,
      investmentCurrent: 0,
      auctionList: [],
      auctionTotal: 0,
      auctionCurrent: 0,
      assetList: [],
      assetTotal: 0,
      assetCurrent: 0,
      announcementList: [],
      announcementListData: [],
      announcementLoading: false,
      announcementPagination: {
        onChange: (page) =>
          this.getAnnouncementList(this.announcementList[1].id, page),
        current: 1, // 当前页数
        total: 0,
        pageSize: 5,
      },
      bannerList: [],
      tenderList: [],
      policyLoading: false,
      newsLoading: false,
      helpList: [
        {
          title: "交易流程",
          icon1: require("@/assets/icon/交易流程.png"),
          key: "资产交易流程规范",
        },
        {
          title: "出价规则",
          icon1: require("@/assets/icon/出价规则.png"),
          key: "出价竞拍",
        },
        {
          title: "成交规则",
          icon1: require("@/assets/icon/成交规则.png"),
          key: "竞拍成功",
        },
        {
          title: "收费标准",
          icon1: require("@/assets/icon/收费标准.png"),
          key: "平台收费规则",
        },
        {
          title: "下载中心",
          icon1: require("@/assets/icon/下载中心.png"),
          key: "资产交易委托书",
        },
        {
          title: "产权转让",
          icon1: require("@/assets/icon/资产招商.png"),
          key: "产权转让操作规则",
        },
      ],
      loginList: [
        {
          title: "统一身份认证登录",
          icon1: require("@/assets/icon/7统一身份认证登陆.png"),
          icon2: require("@/assets/icon/7统一身份认证登陆-鼠标滑过.png"),
        },
        {
          title: "凭证流转平台登陆",
          icon1: require("@/assets/icon/8凭证流转平台登陆.png"),
          icon2: require("@/assets/icon/8凭证流转平台登陆-鼠标滑过.png"),
        },
        {
          title: "资产交易平台登陆",
          icon1: require("@/assets/icon/9资产交易平台登陆.png"),
          icon2: require("@/assets/icon/9资产交易平台登陆-鼠标滑过.png"),
        },
      ],
      speedList: [
        {
          title: "罚没资产",
          code: "B05A01",
          icon1: require("@/assets/icon/9资产交易平台登陆.png"),
          icon2: require("@/assets/icon/9资产交易平台登陆-鼠标滑过.png"),
        },
        {
          title: "个人资产",
          code: "B05A02",
          icon1: require("@/assets/icon/7统一身份认证登陆.png"),
          icon2: require("@/assets/icon/7统一身份认证登陆-鼠标滑过.png"),
        },
        {
          title: "国有企业资产",
          code: "B05A03",
          icon1: require("@/assets/icon/2国有企业资产.png"),
          icon2: require("@/assets/icon/2国有企业资产-鼠标滑过.png"),
        },
        {
          title: "国有自然资源资产",
          code: "B05A04",
          icon1: require("@/assets/icon/3国有自然资源资产.png"),
          icon2: require("@/assets/icon/3国有自然资源资产-鼠标滑过.png"),
        },
        {
          title: "私营企业资产",
          code: "B05A05",
          icon1: require("@/assets/icon/6私营企业资产.png"),
          icon2: require("@/assets/icon/6私营企业资产-鼠标滑过.png"),
        },
        {
          title: "市政公共资源资产",
          code: "B05A06",
          icon1: require("@/assets/icon/4市政公共资源资产.png"),
          icon2: require("@/assets/icon/4市政公共资源资产-鼠标滑过.png"),
        },
        {
          title: "行政事业单位资产",
          code: "B05A07",
          icon1: require("@/assets/icon/1行政事业单位资产.png"),
          icon2: require("@/assets/icon/1行政事业单位资产-鼠标滑过.png"),
        },
        {
          title: "农村集体资产",
          code: "B05A08",
          icon1: require("@/assets/icon/5农村集体资产.png"),
          icon2: require("@/assets/icon/5农村集体资产-鼠标滑过.png"),
        },
        {
          title: "流转平台",
          code: "",
          icon1: require("@/assets/icon/流转平台.png"),
          icon2: require("@/assets/icon/流转平台-鼠标滑过.png"),
        },
        // {
        //   title: "商城平台",
        //   code: "",
        //   icon1: require("@/assets/icon/商城平台.png"),
        //   icon2: require("@/assets/icon/商城平台-鼠标滑过.png"),
        // },
      ],
      cooperateList: [
        {
          title: "相关合作单位推荐1",
          icon1: require("@/assets/img/asset_pic.jpg"),
        },
        {
          title: "相关合作单位推荐2",
          icon1: require("@/assets/img/asset_pic.jpg"),
        },
        {
          title: "相关合作单位推荐3",
          icon1: require("@/assets/img/asset_pic.jpg"),
        },
        {
          title: "相关合作单位推荐4",
          icon1: require("@/assets/img/asset_pic.jpg"),
        },
        {
          title: "相关合作单位推荐5",
          icon1: require("@/assets/img/asset_pic.jpg"),
        },
        // {
        //   title: "相关合作单位推荐6",
        //   icon1: require("@/assets/img/asset_pic.jpg"),
        // },
      ],
    };
  },
  created() {
    this.getBanner();
    //待运营资产
    this.getTobeoperatingAssetsList();
    //this.getReportStatistics();
    // this.getReportStatistics();

    //资产
    this.getAssetsList();

    //招商资产
    this.getCourtshipAssetsList();
    //拍卖会
    this.getAuctionList();

    //新闻
    // this.getNavigation("news").then((item) => {
    //   this.getLastNewsList(this.newsList[0].id);
    //   this.getAnnouncementList(this.announcementList[0].id);
    //   this.getPolicyList(this.policyList[0].id);
    // });

    this.getNavigation("news").then((item) => {
      this.getLastNewsList(this.newsList[0].id);
    });
    this.getNavigation("policy").then(() => {
      this.getPolicyList(this.policyList[0].id);
    });
    this.getNavigation("announcement").then(() => {
      this.getAnnouncementList(this.announcementList[1].id);
    });
    this.getNavigation("tender");
    this.getAgencyList();
  },
  methods: {
    moment,
    handleJump(type) {
      if (type == "流转平台") {
        window.open(process.env.VUE_APP_VTP_BASE_URL, "_blank");
      } else {
        window.open(process.env.VUE_APP_SHOP_BASE_URL, "_blank");
      }
    },
    handleMouseOver(record) {
      let icon = record.icon1;
      record.icon1 = record.icon2;
      record.icon2 = icon;
    },
    customRow(record, path) {
      return {
        on: {
          click: () => {
            if (path == "/assetDetail") {
              this.$router.push({
                path: path,
                query: {
                  assetNumber: record.assetNumber,
                  anouncementId: record.anouncementId,
                },
              });
            } else {
              this.$router.push({ path: path, query: { id: record.id } });
            }
          },
        },
      };
    },

    getAgencyList(page = 1) {
      this.$services.news
        .getAgencyList({
          pageSize: 50,
          pageNo: page,
        })
        .then((res) => {
          if (res.code == 200) {
            this.agencyData = res.result.records;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    handleAttractDetails(record) {
      this.$router.push({ path: "/attract", query: { id: record.id } });
      //  router.push({ path: "/operate", query: { id: record.id } });
    },
    handleAuctionDetails(record) {
      this.$router.push({ path: "/auctionDetail", query: { id: record.id } });
      //  router.push({ path: "/operate", query: { id: record.id } });
    },
    //拍卖会
    getAuctionList(page = 1) {
      this.$services.news
        .getAuctionList({
          name: "",
          pageSize: 4,
          pageNo: page,
        })
        .then((res) => {
          if (res.code == 200) {
            this.auctionList = res.result.records;
            this.auctionTotal = res.result.total;
            this.auctionCurrent = res.result.current;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    //轮播图
    getBanner() {
      this.$services.news.getBanner({}).then((res) => {
        if (res.code == 200) {
          this.bannerList = res.result;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //结果公示
    getAnnouncementList(key, page = 1) {
      this.announcementLoading = true;
      this.$services.news
        .getAnnouncementList({
          navigation: key,
          pageSize: 6,
          pageNo: page,
        })
        .then((res) => {
          if (res.code == 200) {
            this.announcementListData = res.result.records;
            this.announcementPagination.total = res.result.total;
            this.announcementPagination.current = res.result.current;
          } else {
            this.$message.error(res.message);
          }
          this.announcementLoading = false;
        });
    },
    //更多
    handleMore(path, key) {
      router.push({ path: path, query: { key: key } });
    },
    //招商资产
    getCourtshipAssetsList(page = 1) {
      this.$services.news
        .getCourtshipAssetsList({
          pageSize: 4,
          pageNo: page,
        })
        .then((res) => {
          if (res.code == 200) {
            this.investmentList = res.result.records;
            this.investmentTotal = res.result.total;
            this.investmentCurrent = res.result.current;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    //资产列表
    getAssetsList(page = 1) {
      this.$services.news
        .getAssetsList({
          pageSize: 4,
          pageNo: page,
        })
        .then((res) => {
          if (res.code == 200) {
            this.assetList = res.result.records;
            this.assetTotal = res.result.total;
            this.assetCurrent = res.result.current;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    //待运营资产统计信息
    getReportStatistics() {
      this.$services.news.getReportStatistics({}).then((res) => {
        if (res.code == 200) {
          this.reportStatisticsData[0].value = res.result.numberAssetsSum;
          this.reportStatisticsData[1].value = String(
            Math.floor(res.result.coveredAreaSum)
          ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.reportStatisticsData[2].value = String(
            Math.floor(res.result.evaluationPriceSum / 10000)
          ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.$message.error(res.message);
        }
      });
    },

    getTobeoperatingAssetsList(page = 1) {
      // console.log(page);
      this.$services.news
        .getTobeoperatingAssetsList({
          pageSize: 4,
          pageNo: page,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tobeoperatingList = res.result.records;
            this.tobeoperatingTotal = res.result.total;
            this.tobeoperatingCurrent = res.result.current;
          } else {
            this.$message.error(res.message);
          }
        });
    },

    //获取新闻分类
    getNavigation(nav) {
      return this.$services.news.getNavigation(nav).then((res) => {
        if (res.code == 200) {
          if (nav == "news") {
            this.newsList = res.result.filter((item) => item.category == nav);
            this.newsActiveKey = this.newsList[0].id;
          } else if (nav == "policy") {
            this.policyList = res.result.filter((item) => item.category == nav);
            this.policyActiveKey = this.policyList[0].id;
          } else if (nav == "announcement") {
            this.announcementList = res.result.filter(
              (item) => item.category == nav
            );
          } else if (nav == "tender") {
            this.tenderList = res.result.filter((item) => item.category == nav);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取热点新闻
    getLastNewsList(key) {
      this.newsLoading = true;
      this.$services.news.getLastNewsList({ navigation: key }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.lastNewsListData = res.result;
        } else {
          this.$message.error(res.message);
        }
        this.newsLoading = false;
      });
    },
    //新闻分页
    tabChange(key, type) {
      if (type == "policy") {
        this.policyActiveKey = key;
        this.getPolicyList(key);
      } else {
        this.newsActiveKey = key;
        this.getLastNewsList(key);
      }
    },
    getPolicyList(key, page = 1) {
      this.policyLoading = true;
      this.$services.news
        .getPolicyList({ navigation: key, pageSize: 5, pageNo: page })
        .then((res) => {
          if (res.code == 200) {
            this.policyListData = res.result.records;
          } else {
            this.$message.error(res.message);
          }
          this.policyLoading = false;
        });
    },
    handleArticleDetail(record) {
      router.push({ path: "/article", query: { id: record.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.red-title {
  color: #a15d50;
}
.main-table {
  width: 930px;
}
.main-tool {
  width: 260px;
  text-align: center;
  display: flex;
  flex-direction: column;
  .tool-title {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #434242;
    color: #fff;
    font-size: 17px;
  }
}
.cooperate-content {
  margin-top: 20px;
  .cooperate-box {
    width: 260px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cooperate-img {
      width: 200px;
      height: 100px;
    }
    .cooperate-text {
      color: #000;
    }
  }
}
.tool-content {
  margin-top: 5px;
  width: 260px;
  height: 221px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.tool-box {
  width: 100px;
  height: 65px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tool-img {
    width: 50px;
    height: 50px;
  }
  .tool-text {
  }
}
.table-title {
  // width: 930px;
  height: 48px;
  display: flex;
  padding: 0 20px 0 40px;
  justify-content: space-between;
  align-items: center;
  background: #434242;
  .table-title-img {
    width: 20px;
    height: 20px;
    margin-top: -5px;
  }
  .table-title-text {
    color: #fff;
    font-size: 17px;
    margin-left: 5px;
  }
  .table-title-more {
    color: #fff;
    font-size: 13px;
  }
}
.main-apply {
  // width: 1200px;
  height: 130px;
  .apply-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .apply-box {
      background: url(@/assets/img/psm04uea5d59bufq9uyemltfii50bmspvpi57aa0bf5-a2ba-4c10-8398-4f59103bff04.png)
        100% no-repeat;
      background-size: 100% 100%;
      width: 365px;
      height: 215px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 23px;
      font-weight: 700;
      margin: auto;
    }
    .apply-name {
      width: 365px;
      height: 50px;
      font-size: 16px;
      background-color: #fff;
      line-height: 50px;
      text-align: center;
      margin: auto;
    }
  }
}
.apply-background {
  padding: 10px 0;
  width: 100%;
  background-color: #f1f2f6;
}
.news-banner {
  width: 100%;
  padding: 10px 0;
  // display: flex;
  // justify-content: center;
  // margin: 0 auto;
  // margin-left: -400px;
  background: url("@/assets/img/newsbanner.jpg");
}
.main-substance {
  height: 330px;
  display: flex;

  .news-substance {
    width: 580px;
    height: 330px;
    position: relative;
    img {
      width: 580px;
      height: 320px;
    }
    .news-banner-title {
      position: absolute;
      width: 580px;
      bottom: 0;
      height: 30px;
      z-index: 999;
      line-height: 30px;
      // width: 100%;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .news-content {
    width: 620px;
    padding: 10px 10px 10px 40px;
    height: 330px;
    font-size: 17px;
    .news-recommend {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-bottom: 1px solid #bfbfbf;
      .news-title-first {
        font-weight: 700;
        color: #000;
      }
      .news-introduce {
        flex-wrap: wrap;
        color: #bfbfbf;
      }
      .news-item {
        display: flex;
        flex-direction: row;
        color: #bfbfbf;
        justify-content: space-between;
        margin-bottom: 10px;
        .news-source {
        }
        .news-time {
        }
      }
    }
    .news-list {
      display: flex;
      flex-direction: column;

      .new-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        .news-head {
          font-weight: 400;
          color: #000;
          .news-dot {
            margin-bottom: 6px;
            width: 3px; /* 圆点的宽度 */
            height: 3px; /* 圆点的高度 */
            background-color: #000; /* 圆点的颜色，这里是黑色 */
            border-radius: 50%; /* 将div变成圆形 */
            display: inline-block; /* 使得div可以像文本一样进行行内排版 */
          }
        }
        .news-time {
          color: #bfbfbf;
        }
      }
    }
  }
}
.main-result {
  height: 330px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .main-result-content {
    width: 580px;
    height: 80px;
    display: flex;
    flex-direction: row;
    .result-box {
      box-shadow: 0px 0px 10px 0px rgba(37, 38, 38, 0.3);
      background-color: rgba(255, 255, 255, 1);
      width: 80px;
      height: 80px;
    }
    .result-group {
      width: 80px;
      height: 80px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .result-time-dd {
        font-size: 22px;
        color: #000;
        font-weight: 700;
      }
      .result-time-yy {
        font-size: 15px;
        color: #000;
        font-weight: 500;
      }
    }
    .result-content {
      box-shadow: 0px 0px 10px 0px rgba(37, 38, 38, 0.3);
      background-color: rgba(255, 255, 255, 1);
      width: 500px;
      height: 80px;
      // margin-left: 10px;
      // justify-content: flex-center;
      .result-title {
        width: 480px;
        height: 40px;
        margin: 10px 0 0 20px;
        font-size: 17px;
        color: #000;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .result-text {
        margin-left: 20px;
        width: 480px;
        height: 20px;
      }
    }
  }
}
.main-height {
  min-height: 1000px;
}
.main-banner {
  height: 500px;
  width: 100%;
}
.main-content {
  width: 1200px;
  margin: 0 auto;
}
.main-title {
  width: 1200px;
  height: 40px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  .main-head-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 3px 0;
    padding: 0;
    .head-title {
      display: flex;
      flex-direction: row;
      .vertical-line {
        height: 20px;
        width: 1px;
        margin-top: 6px;
        border-left: 3.5px solid #000;
      }
      .vertical-title {
        color: #000;
        height: 40px;
        line-height: 40px;
        margin-left: 12px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .vertical-more {
      color: #000;
      line-height: 40px;
    }
  }

  .horizontal-line {
    width: 100px;
    border-bottom: 2px solid #000;
  }
  .horizontal-line-yellow {
    width: 100px;
    border-bottom: 2px solid #ffe458;
  }
  .horizontal-line-long {
    border-bottom: 1px solid #000;
  }
  .horizontal-line-gray {
    border-bottom: 1px solid #00000002;
  }
}

.section1 {
  width: 1200px;
  margin: 50px auto;
  .left {
    width: 913px;
    border: 1px solid #c5c5c5;
    padding: 20px;
    .section1-title {
      height: 40px;
      background: #f7f8fa;
      border-left: 4px solid var(--primary-color);
    }
    .c1 {
      width: 330px;
    }
    .c2 {
      width: 135px;
    }
    .c3 {
      width: 80px;
    }
    .c4 {
      width: 105px;
    }
    .c5 {
      width: 140px;
    }
  }
  .right {
    width: 447px;
    border: 1px solid #c5c5c5;
    .home-header-nav-item {
      height: 57px;
      line-height: 57px;
      padding: 0 20px;
      border-bottom: 1px dashed #c5c5c5;
      cursor: pointer;
    }
    .home-header-nav-item:hover {
      color: #399777;
    }
    .home-header-nav-item:last-child {
      border-bottom: none;
    }
  }
}
.cc_text_31:hover {
  color: red;
}
.auction-process {
  font-size: 20px;
  text-align: center;
  color: #70a6f1;
}
.card-grid {
  width: 25%;
  text-align: center;
  font-size: 16px;
}
.card-grid:hover {
  color: #70a6f1;
}
/deep/ .slick-prev:before,
/deep/ .slick-next:before {
  color: #70a6f1; /* 文本颜色 */
}

.apply-banner {
  // background: #2bc593;
  border: 1px solid #000;
  background-color: #fff;
  font-size: 20px;
  font-weight: bold;
  height: 80px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  // line-height: 80px;
  text-align: center;
}
.awaiting-tab {
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-size: 20px;
  background-color: #88b1ad;
  color: white;
  border-radius: 5px;
  .awaiting-body {
    color: #fcf4e5;
    font-size: 30px;
  }
}

.card-title {
  font-size: 25px;
  color: #fcf4e5;
  font-weight: bold;
}
.card-subtitle-title {
  font-size: 20px;
  color: azure;
}
.more {
  font-size: 16px;
  color: azure;
}
/deep/.ant-card-head {
  //background-image: url(../../assets/img/banner.jpg);
  background-color: #70a6f1;
}

/deep/ .ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}

/deep/ .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
/deep/ .ant-carousel .custom-slick-arrow:before {
  display: none;
}
/deep/ .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}

/deep/ .ant-carousel .slick-slide h3 {
  color: #fff;
}
// /deep/ .ant-carousel .slick-slide {
//   text-align: center;
//   line-height: 500px;
//   height: 500px;
//   // background: #66df42;
//   overflow: hidden;
// }

// /deep/ .ant-carousel .slick-slide h3 {
//   color: #fff;
// }

.banner-button {
  font-size: 40px;
}
.banner-button:hover {
  font-size: 40px;
  color: #333;
}
.banner-button-news:hover {
  color: #333;
}
.main-quick {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.quick-entrance {
  width: 110px;
  text-align: center;
  padding: 10px;
  border: 1px solid #d3d3d3;
}
.quick-entrance:hover {
  background-color: #ffd700;
  cursor: pointer;
}
.center-banner {
  height: 240px;
  margin: 20px 0;
  position: relative;
  background-image: url(../../assets/img/center.png);
  .center-title {
    font-size: 40px;
    text-align: center;
    line-height: 100px;
    color: #fff;
  }
  .center-list {
    display: flex;
    justify-content: center;
  }
  .hexagon {
    margin-left: 80px;
    margin-top: 30px;
    // position: absolute;
    // border: 1px solid #d3d3d3;
    // bottom: -100px;
    // width: 150px; /* 控制宽度 */
    // height: 200px; /* 高度是宽度的两倍，以模拟竖着的六边形 */
    // background-color: #fff;
    // clip-path: polygon(
    //   50% 0%,
    //   /* 顶部中点 */ 100% 25%,
    //   /* 右上角 */ 100% 75%,
    //   /* 右下角 */ 50% 100%,
    //   /* 底部中点 */ 0% 75%,
    //   /* 左下角 */ 0% 25% /* 左上角 */
    // );
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  }
}
.diamond-text {
  text-anchor: middle; /* 使文本居中 */

  alignment-baseline: middle; /* 尝试使文本垂直居中（但SVG中文本的垂直居中可能需要额外的技巧） */

  font-size: 16px; /* 文本大小 */

  fill: #333; /* 文本颜色 */
}
/deep/.ant-table {
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}
</style>
