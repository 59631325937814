import _this from '@/main.js'
export default {
	namespaced: true,
	state: {
		homeMenuDefaultSelectedKeys:[0],
		menuIndex:[0,1,2,],
		homeData: null,
		homeStatus: false,
		fromRouter: null,
		routerDrawer: 0,
		tenderAddVisible: false,
		tenderVisible: false,
		onlineEditingVisible: false,
		visibleAll: [],
		routesChildrens:[],

	},

	mutations: {
		setHomeData(state, homeData) {
			state.homeData = homeData
		},
		setHomeStatus(state, homeStatus) {
			state.homeStatus = homeStatus
		},
		setFromRouter(state, fromRouter) {
			state.fromRouter = fromRouter;
		},
		setrouterDrawer(state, routerDrawer) {
			state.routerDrawer = routerDrawer
		},
		setmenuKey(state,index){
			state.homeMenuDefaultSelectedKeys = [index];

		},
		setVisible(state, visible) {
			// console.log("set", state[visible], visible, state.visibleAll)
			state[visible] = true
			state.visibleAll.push({ 'visible': visible })
		},
		delVisible(state) {
			// console.log("del", state[state.visibleAll[state.visibleAll.length - 1].visible])
			// console.log(state.fromRouter)
			// console.log(state.visibleAll)
			if (state.visibleAll.length) {
				state[state.visibleAll[state.visibleAll.length - 1].visible] = false
				state.visibleAll.splice(state.visibleAll.length - 1, 1)
			}
			if (state.fromRouter != null) {
				// console.log(state.visibleAll)
				if (state.visibleAll.length >= 1) {
					return
				} else {
					_this.$router.push({
						path: '/' + state.fromRouter,
					});
					state.fromRouter = null
				}

			}

		}
	}
}
