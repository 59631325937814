var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-list',{attrs:{"pagination":_vm.pagination,"data-source":_vm.list,"grid":{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{key:"item.title",on:{"click":function($event){return _vm.handleAuctionDetails(item)}}},[_c('a-row',{staticClass:"list-item"},[_c('a-col',{staticStyle:{"padding":"5px","overflow":"hidden"},attrs:{"span":10}},[(item.auctionPictures)?_c('img',{staticClass:"list-img",attrs:{"alt":"logo","src":item.auctionPictures
              ? _vm.$getFileUrl(item.auctionPictures.split(',')[0])
              : '../../assets/img/asset_pic.jpg'}}):_c('img',{staticClass:"list-img",attrs:{"alt":"logo","src":require("../../assets/img/asset_pic.jpg")}})]),_c('a-col',{staticClass:"list-content",attrs:{"span":14}},[_c('a-row',{staticStyle:{"padding":"10px"}},[_c('div',{staticStyle:{"height":"55px"}},[_c('a',{staticClass:"item-title",on:{"click":function($event){return _vm.handleAuctionDetails(item)}}},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(item.name)+" ")]),_vm._v(" "+_vm._s(item.name)+" ")],2)],1)]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v("拍卖企业："+_vm._s(item.auctionUnit))]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('a-icon',{attrs:{"type":"property-safety"}}),_vm._v(" 付款方式：线下付款 ")],1),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('a-icon',{attrs:{"type":"history"}}),_vm._v(" 报名开始时间："+_vm._s(item.registrationStartTime)+" ")],1)]),_c('a-row',{staticClass:"list-buttom"},[_c('a-col',{staticClass:"list-buttom-red",attrs:{"span":12}},[(item.auctionStatus_dictText == '即将开始')?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('span',[_vm._v("还剩  ")]),_c('a-statistic-countdown',{attrs:{"valueStyle":{
                  fontSize: '14px',
                  color: '#000',
                  lineHeight: '30px',
                },"value":new Date(item.registrationEndTime).getTime(),"format":"D 天 H 时 m 分 s 秒"}})],1):_vm._e(),(item.auctionStatus_dictText == '竞价中')?_c('div',[_vm._v(" "+_vm._s(item.auctionStatus_dictText)+" ")]):_vm._e(),(item.auctionStatus_dictText == '已结束')?_c('div',[_vm._v(" "+_vm._s(item.auctionStatus_dictText)+" ")]):_vm._e()]),_c('a-col',{staticClass:"list-buttom-gray",attrs:{"span":12}},[_c('span',[_vm._v(_vm._s(item.auctionStatus_dictText))])])],1)],1)],1)],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }