import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import homeData from './modules/homeData'
import permission from './modules/permission'
import user from './modules/user'


Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user,
        account,
        homeData,
        permission
    }
})

export default store
