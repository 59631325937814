<template>
  <div id="bottom">
    <a-row class="flex-center" style="height: 160px">
      <a-col :span="8">
        <a-row>
          <a-col :span="8"
            ><div>竞买人帮助</div>
            <div>交易流程</div>
            <div>出价规则</div>
            <div>成交规则</div></a-col
          >
          <a-col :span="8">
            <div>支付帮助</div>
            <div>交保证金</div>
            <div>支付尾款</div>
            <div>收费规则</div>
          </a-col>
          <a-col :span="8">
            <div>常见问题</div>
            <div>办理过户</div>
            <div>操作流程</div>
            <div>资料下载</div>
          </a-col>
        </a-row> </a-col
      ><a-col :span="6"
        ><a-row class="flex-center">
          <a-col :span="12">
            <a-row>
              <div>进入小程序</div>
            </a-row>
            <a-row>
<!--              <img-->
<!--                style="width: 100px; height: 100px"-->
<!--                src="../assets/img/preparation.png"-->
<!--                alt=""-->
<!--              />-->
            </a-row>
          </a-col>
          <a-col :span="12"
            ><a-row>
              <div>关注公众号</div>
            </a-row>
            <a-row>
<!--              <img-->
<!--                style="width: 100px; height: 100px"-->
<!--                src="../assets/img/preparation.png"-->
<!--                alt=""-->
<!--              />-->
            </a-row> </a-col
        ></a-row> </a-col
      ><a-col :span="8"
        >关于我们 客服专线：0472-6916407 客服邮箱：btzxzh@126.com
        联系地址：内蒙古包头市九原区赛汗街道祥和南路68号新都市区商务大厦C座10楼1008室</a-col
      >
    </a-row>

    <a-row style="height: 40px; text-align: center">
      <a-col :span="24"
        >· Copyright © 2024 All Rights Reserved · 备案号：蒙ICP备0000000000号 ·
        今日访问量：23 · 总访问量：125212</a-col
      >
    </a-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
#bottom {
  width: 100%;
  height: 200px;
  color: #fff;

  background-color: #000;
}
.flex-center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
}
</style>