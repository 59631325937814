<template>
  <a-list
    :pagination="pagination"
    :data-source="list"
    :grid="{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }"
  >
    <a-list-item
      slot="renderItem"
      key="item.title"
      slot-scope="item, index"
      @click="handleAuctionDetails(item)"
    >
      <a-row class="list-item">
        <!-- <div class="list-dept">
          <img src="../../assets/img/dept.png" alt="" />
        </div>
        <div class="list-dept-font">
          <div>可用债权抵付</div>
        </div> -->
        <a-col :span="10" style="padding: 5px; overflow: hidden">
          <!-- <div>i</div> -->
          <img
            v-if="item.auctionPictures"
            class="list-img"
            alt="logo"
            :src="
              item.auctionPictures
                ? $getFileUrl(item.auctionPictures.split(',')[0])
                : '../../assets/img/asset_pic.jpg'
            "
          />
          <img
            v-else
            class="list-img"
            alt="logo"
            src="../../assets/img/asset_pic.jpg"
          />
        </a-col>
        <a-col :span="14" class="list-content">
          <a-row style="padding: 10px">
            <div style="height: 55px">
              <a @click="handleAuctionDetails(item)" class="item-title"
                ><a-tooltip>
                  <template slot="title">{{ item.name }} </template>
                  {{ item.name }}
                </a-tooltip></a
              >
            </div>

            <div style="margin-top: 10px">拍卖企业：{{ item.auctionUnit }}</div>
            <div style="margin-top: 10px">
              <a-icon type="property-safety" /> 付款方式：线下付款
            </div>
            <div style="margin-top: 10px">
              <a-icon type="history" /> 报名开始时间：{{
                item.registrationStartTime
              }}
            </div>
          </a-row>
          <a-row class="list-buttom">
            <a-col class="list-buttom-red" :span="12">
              <div
                style="display: flex; justify-content: center"
                v-if="item.auctionStatus_dictText == '即将开始'"
              >
                <span>还剩 &nbsp;</span
                ><a-statistic-countdown
                  :valueStyle="{
                    fontSize: '14px',
                    color: '#000',
                    lineHeight: '30px',
                  }"
                  :value="new Date(item.registrationEndTime).getTime()"
                  format="D 天 H 时 m 分 s 秒"
                />
              </div>
              <!-- <div
                style="display: flex; justify-content: center"
                v-if="
                  new Date() > new Date(item.registrationStartTime) &&
                  new Date() < new Date(item.registrationEndTime)
                "
              >
                <span>还剩 &nbsp;</span
                ><a-statistic-countdown
                  :valueStyle="{
                    fontSize: '14px',
                    color: '#000',
                    lineHeight: '30px',
                  }"
                  :value="new Date(item.registrationEndTime).getTime()"
                  format="D 天 H 时 m 分 s 秒"
                />
              </div> -->
              <div v-if="item.auctionStatus_dictText == '竞价中'">
                {{ item.auctionStatus_dictText }}
              </div>
              <div v-if="item.auctionStatus_dictText == '已结束'">
                {{ item.auctionStatus_dictText }}
              </div>
            </a-col>
            <a-col class="list-buttom-gray" :span="12">
              <span>{{ item.auctionStatus_dictText }}</span>
              <!-- <span v-if="new Date() < new Date(item.registrationStartTime)"
                >即将开始</span
              >
              <span
                v-if="
                  new Date() > new Date(item.registrationStartTime) &&
                  new Date() < new Date(item.registrationEndTime)
                "
                >正在报名</span
              >
              <span v-if="new Date() > new Date(item.registrationEndTime)"
                >报名结束</span
              > -->
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- <a-row class="list-item" :gutter="[8, 8]">
        <a-col :span="8"
          ><img
            style="height: 140px; width: 100%"
            alt="logo"
            :src="item.auctionPictures"
        /></a-col>
        <a-col :span="16">
          <a-list-item-meta>
            <a
              @click="handleAuctionDetails(item)"
              slot="title"
              class="item-title"
              ><a-tooltip>
                <template slot="title">{{ item.name }} </template
                >{{
                  item.name.length > 20
                    ? item.name.substr(0, 20) + "..."
                    : item.name
                }}
              </a-tooltip></a
            >
            <span slot="description"
              ><a-icon type="home" /> 拍卖企业：{{ item.auctionUnit }}</span
            >
          </a-list-item-meta>
          <div style="margin-top: 10px">
            <a-icon type="property-safety" /> 付款方式：线下付款
         
          </div>
          <div style="margin-top: 10px">
            <a-icon type="history" /> 报名开始时间：{{
              item.registrationStartTime
            }}
          </div>

          <a-row class="list-title">
            <a-col :span="16" style="margin-top: 10px">
              <span style="margin-right: 20px"
                ><a-icon type="reconciliation" />
                {{ item.assetCount }}个资产</span
              >
             
            </a-col>
            <a-col :span="8">
              <a-button
                v-if="new Date()<new Date(item.registrationStartTime)"
                @click="handleAuctionDetails(item)"
                type="danger"
                >即将开始<a-icon type="right"
              /></a-button>
              <a-button
                  v-if="new Date()>new Date(item.registrationStartTime)&&new Date()<new Date(item.registrationEndTime)"
                  @click="handleAuctionDetails(item)"
                  type="danger"
              >正在报名<a-icon type="right"
              /></a-button>
              <a-button
                v-if="new Date()>new Date(item.registrationEndTime)"
                @click="handleAuctionDetails(item)"
                type="primary"
                >报名结束<a-icon type="right"
              /></a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row> -->
    </a-list-item>
  </a-list>
</template>

<script>
import router from "@/router";

export default {
  name: "AuctionList",
  props: ["list", "total", "current"],
  data() {
    return {
      //   listData,
      pagination: {
        // onChange: (page) => this.$parent.getNewsList(page),
        // current: 1, // 当前页数
        // total: 0,
        // pageSize: 5,
      },
    };
  },
  beforeUpdate() {
    this.pagination = {
      onChange: (page) => this.$emit("getAuctionList", page),
      current: this.current, // 当前页数
      total: this.total,
      pageSize: 10,
    };
  },

  methods: {
    handleAuctionDetails(record) {
      router.push({ path: "/auctionDetail", query: { id: record.id } });
      //  router.push({ path: "/operate", query: { id: record.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.list-item {
  position: relative;
  margin-left: 10px;
  background-color: #fff;
  // border: 1px #eaedf2 solid;
  border: none;
  border-radius: 15px;
  color: #fff;
  height: 210px;
  // overflow: hidden;
  .list-dept {
    z-index: 2;
    position: absolute;
    top: 5px;
    left: -12px;
  }
  .list-dept-font {
    font-size: 16px;
    z-index: 3;
    position: absolute;
    top: 20px;
    left: 17px;
  }
  .list-img {
    // margin: 5px 5px 0px 5px;
    transition: transform 0.5s ease;
    border-radius: 15px;
    height: 200px;
    width: 100%;
  }
  .list-img:hover {
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); /* 放大到1.5倍 */
  }

  .list-content {
    color: #000;
    position: relative;
    height: 210px;
    .list-buttom {
      width: 100%;
      height: 30px;
      color: #fff;
      text-align: center;
      line-height: 30px;
      position: absolute;
      bottom: 0;
      .list-buttom-red {
        height: 30px;
        border-top-left-radius: 15px;
        color: #000;
        background-color: #e7e7e7;
      }
      .list-buttom-gray {
        font-weight: 700;
        height: 30px;
        border-bottom-right-radius: 15px;
        background-color: #3d3d3d;
      }
    }
  }
}
.item-title {
  color: #000;
  font-weight: bold;
  font-size: 17px;
}
.list-item:hover {
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2),
    /* 底部阴影 */ 0 5px 5px rgba(0, 0, 0, 0.2),
    /* 左侧阴影 */ -5px 0 5px rgba(0, 0, 0, 0.2),
    /* 右侧阴影 */ 5px 0 5px rgba(0, 0, 0, 0.2);
  /* background-color: rgb(188, 228, 235); */
}
</style>