import "./styles/main.css";
import "./styles/home.rem.css";
import "./styles/common.css";
import "./styles/flexible";

import Vue from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
// import 'ant-design-vue/dist/antd.less';
import store from "./store"; // 引入 Vuex store
import axios from "axios";
import services from "./services";
import router from "./router";
// import './permission'
import "./styles/variables.less";
import SSO from "@/sso";

Vue.use(Antd);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.prototype.$services = services;
Vue.prototype.$store = store;
Vue.prototype.$getFileUrl = function getFileUploadHttpUrl(avatar, subStr) {
  if (!subStr) subStr = 'http'
  try {
    if (avatar && avatar.startsWith(subStr)) {
      return avatar;
    } else {
      if (avatar && avatar.length > 0 && avatar.indexOf('[') == -1) {
        return process.env.VUE_APP_API_FILE_URL + "/" + avatar;
      }
    }
  } catch (err) {
    return;
  }
}

Vue.config.scrict = false;

SSO.init(() => {
  main();
});
function main() {
  new Vue({
    router,
    store,
    mounted() {
      store.commit("SET_TOKEN", window.localStorage.getItem("token"));
      store.commit("SET_USER_INFO", window.localStorage.getItem("userInfo"));
    },
    render: (h) => h(App),
  }).$mount("#app");
}

// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");
