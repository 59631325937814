import Vue from "vue";
import Layout from "@/layout/Layout.vue";
import OverallSituationLayout from "@/layout/OverallSituationLayout.vue";
import BottomPage from "@/layout/BottomPage.vue";
import HomePage from "@/pages/home/HomePage.vue";
import store from "@/store";
// import { createRouter, createWebHistory } from 'vue-router';

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes: [
//     // 你的路由配置
//   ]
// });
import Router from "vue-router";

Vue.use(Router);

let res = [
  {
    path: "/home",
    name: "首页",
    meta: { showInNav: true, keepAlive: true },
    // components: { default: () => import('@/pages/home/HomePage.vue'), bottom: BottomPage, }
    component: () => import("@/pages/home/HomePage.vue"),
  },
  {
    path: "/asset",
    name: "资产",
    meta: { showInNav: true, keepAlive: true },
    component: () => import("@/pages/asset/AssetManagement.vue"),
  },
  {
    path: "/auction",
    name: "拍卖会",
    meta: { showInNav: true, keepAlive: true },
    component: () => import("@/pages/auction/AuctionPage.vue"),
  },
  //  {
  // 	path: '/trading',
  // 	name: '交易公告',
  // 	meta: { showInNav: true ,keepAlive: true},
  // 	component: () => import('@/pages/trading/TransactionAnnouncement.vue'),
  // },
  {
    path: "/investment",
    name: "招商中心",
    meta: { showInNav: true, keepAlive: true },
    component: () => import("@/pages/investment/InvestmentPromotionCenter.vue"),
  },
  {
    path: "/news",
    name: "新闻中心",
    meta: { showInNav: true, keepAlive: true },
    component: () => import("@/pages/news/NewsCenter.vue"),
  },
  {
    path: "/policy",
    name: "政策法规",
    meta: { showInNav: true, keepAlive: true },
    component: () => import("@/pages/policy/PoliciesAndRegulations.vue"),
  },
  // {
  // 	path: '/about',
  // 	name: '关于我们',
  // 	meta: { showInNav: true, keepAlive: true },
  // 	component: () => import('@/pages/about/AboutUs.vue'),
  // },
  {
    path: "/help",
    name: "帮助中心",
    meta: { showInNav: true, keepAlive: true },
    component: () => import("@/pages/help/HelpCenter.vue"),
  },
  {
    path: "/personal",
    name: "个人中心",
    meta: { showInNav: true, keepAlive: false },
    component: () => import("@/pages/personal/PersonalCenter.vue"),
  },
  {
    path: "/article",
    name: "文章详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/article/ArticleDetail.vue"),
  },
  {
    path: "/apply",
    name: "评估申请",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/apply/ApplyAgency.vue"),
  },
  {
    path: "/awaiting",
    name: "代运营资产详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/operate/AssetAwaitingDetails.vue"),
  },
  {
    path: "/operate",
    name: "代运营资产",
    meta: { showInNav: false, keepAlive: true },
    component: () => import("@/pages/operate/AssetsAwaitingOperation.vue"),
  },
  {
    path: "/attract",
    name: "招商资产详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/investment/InvestmentDetails.vue"),
  },
  {
    path: "/login",
    name: "登录",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/login/Login.vue"),
  },
  {
    path: "/personal",
    name: "个人中心",
    meta: { showInNav: false, keepAlive: true },
    component: () => import("@/pages/personal/PersonalCenter.vue"),
  },
  {
    path: "/tender",
    name: "招标公告",
    meta: { showInNav: false, keepAlive: true },
    component: () => import("@/pages/tender/tenderAnnouncement.vue"),
  },
  {
    path: "/invite",
    name: "招标公告详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/tender/tenderDetail.vue"),
  },
  {
    path: "/assetDetail",
    name: "资产详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/asset/AssetDetail.vue"),
  },
  {
    path: "/auctionDetail",
    name: "拍卖会详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/auction/AuctionDetail.vue"),
  },
  {
    path: "/tenderDetail",
    name: "招标详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/tender/tenderDetail.vue"),
  },
  {
    path: "/applicationDetail",
    name: "报名详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/asset/ApplicationDetail.vue"),
  },
  {
    path: "/bondDetail",
    name: "保证金详情",
    meta: { showInNav: false, keepAlive: false },
    component: () => import("@/pages/asset/BondDetail.vue"),
  },
];
export const constantRoutes = [
  {
    path: "/",
    redirect: "/home",
    name: "分页",
    component: Layout,
    children: res,
  },
];

const createRouter = () =>
  new Router({
    routes: constantRoutes,
    mode: "history",
  });

const router = createRouter();

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
